
/*
----------------------------------------------------------
MAIN
----------------------------------------------------------
*/

.main {
  width: 100%;
  max-width: 992px;
  margin: 0 auto;
}

.main .main__inner {
  margin: 0 25px 80px 25px;
}
