
/*
----------------------------------------------------------
COMPONENT: HEADER
----------------------------------------------------------
*/

.google-map {
  width: 100%;
  margin: 60px 0;
}
