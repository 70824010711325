
.events__row {
  display: block;
  width: 100%;
  gap: 0;
}

.events__column {
  display: block;
  width: 100%;
}

@media all and (width >= 768px) {
  
.events__row {
  display: flex;
  flex-direction: row;
  width: 100%; 
}

.events__column {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.events__column-1 {
  margin-right:30px;
}

.events__column-1 {
  margin-left:30px;
}

}
