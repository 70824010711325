
.event {
  margin: 24px 0 60px;
}

.event img {
  width: 100%;
}

.event__description,
.event_when {
  margin: 24px 24px 0;
}

.event__when {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  margin-top: 24px;
}

.event__when .event__date,
.event__when .event__separator,
.event__when .event__time {
  display: inline;
}

.event__when label {
  display: none;
  padding-right: 10px;
}

.event__separator {
  padding: 0 8px;
  color: #b1b1b1;
}

.event__analytics__triggers {
  visibility: hidden;
}

@media all and (width < 375px) {
  .event__when label { display: none; }
}

@media all and (width >= 768px) {

  .event {
    margin: 60px 0 0;
  }

  .event1 .event__inner {
    padding-right: 24px;
  }

  .event2 .event__inner {
    padding-left: 24px;
  }

  .event__description,
  .event__when {
    margin: 20px 24px 0;
  }

  .event_when {
    font-size: 16px;
    line-height: 21px;
  }

}

@media all and (width >= 920px) {

  .event__when label {
    display: initial;
  }

}
